<template>
    <div>
        <!-- 新增人员 -->
        <el-dialog title="管理人员" :visible.sync="dialogVisible" width="700" :show-close="false" append-to-body>
            <template>
                <el-transfer v-model="value" :titles="['未选人员', '已选人员']" :data="data" :props="{
                    key: 'id',
                    label: 'nickname'
                }" filterable></el-transfer>
            </template>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="submit" size="small">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { notMember } from '@/api/system/role'
import { updateMemberRole } from '@/api/system/organization'
export default {

    data() {
        return {
            dialogVisible: false,
            selectedMember: [],
            selectedMemberId: [],
            data: [],
            value: [], // 默认选中的数据
            roleId: null
        }
    },
    methods: {
        // 打开当前模态框
        openMember(val, val2) {
            this.roleId = val;
            this.value = [];
            this.selectedMemberId = [];
            if (val2) {
                this.selectedMember = val2;
                val2.forEach(item => {
                    this.selectedMemberId.push(item.id);
                });
            }
            console.log(this.selectedMemberId);
            this.value = this.selectedMemberId;
            this.getMemberNot();
            this.dialogVisible = true;
        },
        // 获取未选中的人员
        async getMemberNot() {
            const res = await notMember({id: this.roleId})
            console.log(res);
            if (res.code === 200) {
                this.data = res.data;
                this.data = [...this.data, ...this.selectedMember];
            }
        },
        // 点击提交
        async submit() {
            let allList = [];
            // console.log(this.data);
            // console.log(this.value);
            allList = this.data.filter(item => {
                return this.value.indexOf(item.id) == -1
            })
            console.log(allList);
            // let notIdList = [];
            // if (allList.length > 0) {
            //     allList.forEach(item => {
            //         notIdList.push(item.id)
            //     })
            // }
            // console.log(this.roleId);

            const res = await updateMemberRole(JSON.stringify({ roleId: this.roleId, userIdList: this.value, userRoleIsNullIdList: allList}))
            console.log(res);
            if (res.code === 200) {
                this.$message({
                    showClose: true,
                    message: '修改成功',
                    type: 'success'
                });
                this.dialogVisible = false;
                this.$emit('getRoleDetail');
            } else {
                this.$message({
                    showClose: true,
                    message: '修改失败！' + res.msg,
                    type: 'error'
                });
            }
        }
    }

}
</script>

<style lang="scss" scoped>
.el-transfer {
    display: flex;
    justify-content: center;
    align-items: center;

}
</style>