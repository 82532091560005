<template>
  <div>
    <!-- 权限设置 -->
    <el-dialog title="权限设置" :visible.sync="dialogVisible" width="30%" :show-close="false" append-to-body>
      <div>
        <el-tree :data="data" show-checkbox node-key="id" :props="defaultProps" @check-change="handleCheckChange">
        </el-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="submit" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getJurisdictionList, updateJurisdictionList } from '@/api/system/role'
export default {

  data() {

    return {
      dialogVisible: false,
      data: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      roleId: null, //当前角色id
    choseList: []
    }
  },
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => { });
    },
    // 打开当前模态框
    openJurisdiction(val) {
      // console.log(val)
      this.roleId = val;
      this.getList();
      this.dialogVisible = true;
    },
    // 获取权限列表
    async getList() {
      const res = await getJurisdictionList();
      console.log(res);
      if (res.code === 200) {
        this.data = res.data;
      }
    },
    // 获取用户选中的数据
    handleCheckChange(data, checked, indeterminate) {
      console.log(data, checked, indeterminate);
      if (checked === true) {
        
        if (data.children) {
          // this.choseList.push(data.children);
          this.choseList = [...this.choseList,...data.children]
        } else {
          this.choseList.push(data);
        }
      } else {
        this.choseList = this.choseList.filter(item => {
          return item.id !== data.id
        })
      }
      console.log(this.choseList);

      // [1,2,3]
      // [{id: 1, parentId: 2}]
    },
    // 点击确定
    async submit() {
      // console.log(this.choseList);
      // this.dialogVisible = false;
      let arr = [];
      this.choseList.forEach(item => {
        arr.push(item.id,item.parentId)
      })
      console.log(arr);
      console.log(new Set(arr));
      let list  = [...new Set(arr)]
      list = list.filter(item => {
        return item !== 0
      })
      let info = {id: this.roleId, menuIdList: list}
      const res = await updateJurisdictionList(JSON.stringify(info));
      console.log(res);
      if (res.code === 200) {
        this.$message({
          showClose: true,
          message: '修改成功！',
          type: 'success'
        });
        this.dialogVisible = false;
        this.$emit('getRoleDetail')
      } else {
        this.$message({
          showClose: true,
          message: '操作失败！' +res.msg,
          type: 'error'
        });
      }
    }
   
  }

}
</script>

<style lang="scss" scoped>
</style>