<template>
    <div>
      <!-- 权限设置 -->
      <el-dialog title="菜单权限" :visible.sync="dialogVisible" width="30%" :show-close="false" append-to-body>
        <div>
          <el-tree ref="tree" :data="data" show-checkbox node-key="id" :props="defaultProps" :default-checked-keys="defaultData" @check-change="getCheckedNodes">
          </el-tree>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false" size="small">取 消</el-button>
          <el-button type="primary" @click="submit" size="small">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import { getJurisdictionList, getUserJurisdiction,setJurisdictionUser } from '@/api/system/role'
  export default {
  
    data() {
  
      return {
        dialogVisible: false,
        data: [],
        defaultProps: {
          children: 'children',
          label: 'name'
        },
        roleId: null, //当前角色id
      choseList: [], //用户选择的菜单
      defaultData: []
      }
    },
    methods: {
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => { });
      },
      // 打开当前模态框
      openJurisdiction(val) {
        // console.log(val)
        this.defaultData = [];
        this.choseList = [];
        this.roleId = val;
        this.getList();
        this.getDataList();
        this.dialogVisible = true;
      },
      // 获取权限列表
      async getList() {
        const res = await getJurisdictionList();
        console.log(res);
        if (res.code === 200) {
          this.data = res.data;
        }
      },
      // 获取用户选中的数据
      getCheckedNodes() {
        console.log();
        this.choseList  = this.$refs.tree.getCheckedNodes();
      },
      // 点击确定
      async submit() {
        // console.log(this.choseList);
        // // this.dialogVisible = false;
        let arr = [];
        this.choseList.forEach(item => {
          arr.push(item.id,item.parentId)
        })
        console.log(arr);
        console.log(new Set(arr));
        let list  = [...new Set(arr)]
        list = list.filter(item => {
          return item !== 0
        })
        // console.log(this.data)
        let info = {userId: this.roleId, menuIds: list}
        console.log(list)
        const res = await setJurisdictionUser(JSON.stringify(info));
        console.log(res);
        if (res.code === 200) {
          this.$message({
            showClose: true,
            message: '修改成功！',
            type: 'success'
          });
          this.dialogVisible = false;
          this.$emit('getRoleUserList')
        } else {
          this.$message({
            showClose: true,
            message: '操作失败！' +res.msg,
            type: 'error'
          });
        }
      },
      // 获取用户之前的权限
      async getDataList() {
        const res = await getUserJurisdiction({id: this.roleId});
        console.log(res);
        let list = []
        this.defaultData = [];
        if (res.code === 200) {
            list = res.data.menuList;
            // list.forEach(item => {
            //     this.defaultData.push(item.id)
            // })
            this.getMenuIdList(list)
        }
        console.log(this.defaultData)
        // this.choseList = this.defaultData;
      },
      // 使用递归取出菜单权限的id
      getMenuIdList(val) {
            console.log(val);
            if (val) {
                val.forEach(item => {
                   if(item.parentId !== 0){
                    this.defaultData.push(item.id)
                   }
                    // this.choseList.push(item);
                    // this.choseList = [...new Set(this.choseList)];
                    this.getMenuIdList(item.children)
                });
            }
        },
     
    }
  
  }
  </script>
  
  <style lang="scss" scoped>
  </style>