<template>
    <div>
        <!-- 新增角色 -->
        <el-dialog title="新增角色" :visible.sync="dialogVisible" width="30%" :show-close="false">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" size="small">
                <el-form-item prop="name">
                    <span>角色名称</span>
                    <br />
                    <el-input v-model="ruleForm.name" placeholder="请输入角色名称"></el-input>
                </el-form-item>
            </el-form>
            <!-- <span>这是一段信息</span> -->
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="submitForm('ruleForm')" size="small">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { addRoles } from '@/api/system/role'
export default {
    data() {
        return {
            dialogVisible: false,
            ruleForm: {
                name: '',
            },
            rules: {
                name: [
                    { required: true, message: '请输入角色名称', trigger: 'blur' },
                    { max: 12, message: '长度在 12 个字符以内', trigger: 'change' }
                ]
            }
        };
    },
    methods: {
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => { });
        },
        openAdd() {
            this.dialogVisible = true;
        },
        // 点击提交
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    const res = await addRoles({ name: this.ruleForm.name });
                    console.log(res);
                    if (res.code === 200) {
                        this.$message({
                            showClose: true,
                            message: '新增成功！',
                            type: 'success'
                        });
                        this.$emit('getRoleUserList');
                        this.dialogVisible = false;
                    } else {
                        this.$message({
                            showClose: true,
                            message: '新增失败！' + res.msg,
                            type: 'error'
                        });
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    }
}
</script>

<style>
</style>