<template>
  <div>
    <!-- 权限设置 -->
    <el-dialog title="数据权限" :visible.sync="dialogVisible" width="30%" :show-close="false" append-to-body>
      <div>
        <el-tree :data="data" show-checkbox node-key="id" ref="tree" :default-checked-keys="defaultData"
          :props="defaultProps" @check-change="handleCheckChange">
          <!-- @check="nodeClick"  -->
        </el-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="submit" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { setJurisdictionUser, getUserJurisdiction } from '@/api/system/role'
import { getOrganizationList } from '@/api/system/organization'
export default {

  data() {

    return {
      dialogVisible: false,
      data: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      roleId: null, //当前角色id
      choseList: [], //用户选择的菜单
      defaultData: []
    }
  },
  methods: {
    nodeClick(data, checked) {
      console.log(data, checked);
      // 判断是选中还是取消
      // if (checked.checkedKeys.length > 0) {
      // console.log(checked.checkedKeys)
      let status = 0; // 0是取消 1是选中
      for (let i = 0; i < checked.checkedKeys.length; i++) {
        console.log(checked.checkedKeys[i])
        if (data.id === checked.checkedKeys[i]) {
          // console.log('选中')
          status = 1;
        } else {
          // console.log('取消');
        }
      }
      console.log(status);
      if (status === 1) {
        this.choseList.push(data);
      } else {
        this.choseList = this.choseList.filter(item => {
          return item.id !== data.id
        })
      }
      console.log(this.choseList)
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => { });
    },
    // 打开当前模态框
    openJurisdiction(val) {
      // console.log(val)
      this.defaultData = [];
      this.choseList = [];
      this.roleId = val;
      this.getList();
      this.getDataList();
      this.dialogVisible = true;
    },
    // 获取权限列表
    //   async getList() {
    //     const res = await getJurisdictionList();
    //     console.log(res);
    //     if (res.code === 200) {
    //       this.data = res.data;
    //     }
    //   },
    // 获取组织机构列表
    async getList() {
      const res = await getOrganizationList()
      console.log(res);
      if (res.code === 200) {
        this.data = res.data;
        // this.tableData = res.data;
      }
    },
    // 获取用户选中的数据
    handleCheckChange(data, checked, indeterminate) {
      console.log(data, checked, indeterminate);
      if (checked === true) {
        this.choseList.push(data);
        // if (data.children) {
        //   // this.choseList.push(data.children);
        //   this.choseList = [...this.choseList]
        // } else {
        //   this.choseList.push(data);
        // }
      } else {
        this.choseList = this.choseList.filter(item => {
          return item.id !== data.id
        })
        // if(data.children) {
        //   data.children.forEach(item => {
        //     this.choseList = this.choseList.filter(ele => {
        //        return ele.id !== item.id
        //     })
        //   })
        // }
      }
      console.log(this.choseList);

      // [1,2,3]
      // [{id: 1, parentId: 2}]
    },
    // 获取用户选中的数据
    getCheckedNodes() {
      console.log(this.$refs.tree.getCheckedNodes());
      this.choseList = this.$refs.tree.getCheckedNodes();
    },
    // 点击确定
    async submit() {
      // console.log(this.choseList);
      // 判断是否选择第一级
      // let level = 0;
      // if(this.choseList.length > 0) {
      //   this.choseList.forEach(item => {
      //   if (item.level === 1) {
      //     level = 1;
      //   }
      // })
      // }
      // let arr = [];
      // let childrenArr = [];
      // if (level !== 1) {
      //   this.choseList.forEach(item => {
      //     if(item.children) {
      //       item.children.forEach(ele => {
      //         childrenArr.push(ele.id)
      //       })
      //     }
      //     arr.push(item.id)
      //   })
      // } else {
      //   arr = [76]
      // }
      // let list = [...new Set(arr)]
      // let newArr = [];
      // newArr = list.filter(v => !childrenArr.some((item) => item === v))
      // console.log(newArr)
      let newArr = this.$refs.tree.getCheckedKeys();
      let info = { userId: this.roleId, deptIds: newArr }
      const res = await setJurisdictionUser(JSON.stringify(info));
      console.log(res);
      if (res.code === 200) {
        this.$message({
          showClose: true,
          message: '修改成功！',
          type: 'success'
        });
        this.dialogVisible = false;
        this.$emit('getRoleUserList')
      } else {
        this.$message({
          showClose: true,
          message: '操作失败！' + res.msg,
          type: 'error'
        });
      }
    },
    // 获取用户之前的权限
    async getDataList() {
      const res = await getUserJurisdiction({ id: this.roleId });
      console.log(res);
      let list = []
      this.defaultData = [];
      if (res.code === 200) {
        if (res.data.departmentVOList !== null) {
          list = res.data.departmentVOList;
          // this.getMenuIdList(list)
        }
        console.log(list)
        if (list.length > 0) {
          
          this.getMenuIdList(list)
        }
        console.log(this.defaultData)

      }
    },
    // 使用递归取出之前选择的菜单权限的id
    getMenuIdList(val) {
      if (val) {
        val.forEach(item => {
          // 把没有children的放到默认选中的组织机构，防止有父节点的id导致全选
          if(item.children) {
            this.getMenuIdList(item.children)
          } else {
            this.defaultData.push(item.id)
          }
          
        });
      }
    },

  }

}
</script>

<style lang="scss" scoped>

</style>