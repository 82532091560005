<template>
    <div class="roleList">
        <!-- 角色管理 -->
        <!-- <div class="btn">
            <el-button type="primary" size="small" @click="addRole">新增</el-button>
        </div> -->
        <div class="searchBtn">
            <!-- <div class="inputContent"> -->
            <el-input placeholder="请输入姓名" v-model="searchInfo.nickname" clearable size="small">
            </el-input>
            <el-select v-model="searchInfo.sex" placeholder="请选择性别" size="small">
                <el-option label="全部" :value="4">
                </el-option>
                <el-option label="男" :value="1">
                </el-option>
                <el-option label="女" :value="2">
                </el-option>
                <el-option label="未填写" :value="3">
                </el-option>

            </el-select>
            <el-cascader :options="OrganizationsList" v-model="searchInfo.departmentId" size="small"
                placeholder="请选择组织机构" :show-all-levels="false"
                :props="{ value: 'id', label: 'name', checkStrictly: true, emitPath: false }"></el-cascader>

            <el-cascader :options="RolesList"  placeholder="请选择角色" v-model="searchInfo.roleId" size="small" :props="{ value: 'id', label: 'name', emitPath: false}" :show-all-levels="false"></el-cascader>
            <!-- <el-select v-model="searchInfo.roleId" placeholder="请选择角色" size="small">
                <el-option v-for="item in RolesList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
            </el-select> -->
            <!-- </div> -->
            <!-- <div class="btn"> -->
            <el-button type="primary" size="small" @click="search">搜索</el-button>
            <!-- <el-button type="primary" size="small" @click="addRole">新增</el-button> -->
            <el-button type="primary" size="small" @click="deleteUser">删除</el-button>
            <!-- </div> -->
        </div>
        <div class="tableContent">
            <el-table :data="tableData" border style="width: 100%" size="small" @selection-change="handleSelectionChange">
                <el-table-column type="selection">

                </el-table-column>
                <el-table-column label="编号" width="100" fixed>
                    <template slot-scope="scope">
                        <span>{{ scope.row.id }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="nickname" label="姓名" width="150" show-overflow-tooltip  fixed>
                </el-table-column>
                <el-table-column prop="province" label="性别" width="80"  fixed>
                    <template slot-scope="scope">
                        <span v-if="scope.row.sex === 1">男</span>
                        <span v-else-if="scope.row.sex === 2">女</span>
                        <span v-else-if="scope.row.sex === 3">未填写</span>
                    </template>
                </el-table-column>
                <el-table-column prop="birthday" label="出生日期" width="150"></el-table-column>
                <el-table-column prop="telephone" label="手机号" width="120"></el-table-column>
                <el-table-column prop="username" label="登录名" width="150" show-overflow-tooltip>
                </el-table-column>
                <!-- <el-table-column  label="学历" width="150" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.education === 1">初等教育</span>
                        <span v-if="scope.row.education === 2">中等教育</span>
                        <span v-if="scope.row.education === 3">高等教育</span>
                    </template>
                </el-table-column>
                <el-table-column  label="职业" width="150" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.occupation === 1">国家机关、党群组织、企业、事业单位负责人</span>
                        <span v-if="scope.row.occupation === 2">专业技术人员</span>
                        <span v-if="scope.row.occupation === 3">办事人员和有关人员</span>
                        <span v-if="scope.row.occupation === 4">商业、服务业人员</span>
                        <span v-if="scope.row.occupation === 5">农、林、牧、渔、水利业生产人员</span>
                        <span v-if="scope.row.occupation === 6">生产、运输设备操作人员及有关人员</span>
                        <span v-if="scope.row.occupation === 7">军人</span>
                        <span v-if="scope.row.occupation === 8">学生
                        <span v-if="scope.row.grade === 1">
                        小学1-3
                        </span>
                        <span v-if="scope.row.grade === 2">
                        初中1-3"
                        </span>
                        <span v-if="scope.row.grade === 3">
                        高中1-3
                        </span>
                        <span v-if="scope.row.grade === 4">
                        大学1-4
                        </span>
                        </span>
                        <span v-if="scope.row.occupation === 9">不便分类的其他从业人员</span>

                    </template>
                </el-table-column> -->
                <!-- <el-table-column prop="grade" label="年级" width="150" show-overflow-tooltip>
                </el-table-column> -->
                <el-table-column prop="departmentName" :show-overflow-tooltip="true" label="组织机构" width="150">
                </el-table-column>
                <el-table-column prop="roleName" label="角色名称" width="150" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="source" label="用户来源" width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.source === 1"> 后台新增</span>
                        <span v-if="scope.row.source === 2"> 用户注册</span>
                        <span v-if="scope.row.source === 3"> 后台导入</span>
                        <span v-if="scope.row.source === 4"> 游客</span>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间" width="160">
                </el-table-column>
                <el-table-column prop="username" label="禁用">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.status"  @change="changeStatus(scope.row)"
                             :active-value="1" :inactive-value="0">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column prop="username" label="登录后台">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.isBackstage"  @change="changeStatus(scope.row)"
                             :active-value="1" :inactive-value="0">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="150">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" @click="editMenu(scope.row.id)">菜单权限</el-button>
                        <el-button type="text" size="small" @click="editData(scope.row.id)">数据权限</el-button>
                        <!-- <el-button type="text" size="small" @click="deleteUser(scope.row.id)">删除</el-button> -->
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="block">
            <el-pagination @current-change="handleCurrentChange" :current-page="paging.p" :page-size="paging.ps"
                layout="total, prev, pager, next, jumper" :total="paging.total">
            </el-pagination>
            <!-- <el-pagination background layout="prev, pager, next" :total="paging.total"
                @current-change="handleCurrentChange" :current-page="paging.p" :page-size="paging.ps">
            </el-pagination> -->
        </div>
        <!-- <AddRoleVue ref="add" @getRoleUserList="getRoleUserList"></AddRoleVue>
        <EditRoleVue ref="edit" @getRoleUserList="getRoleUserList"></EditRoleVue> -->
        <MenuPermissionsVue ref="menu" @getRoleUserList="getRoleUserList"></MenuPermissionsVue>
        <DataPermissionsVue ref="data" @getRoleUserList="getRoleUserList"></DataPermissionsVue>
    </div>
</template>

<script>
import { getRoleList, deleteRoles, getJurisdictionUserList } from '@/api/system/role'
import {  updateUserStatus,deleteUsersList } from '@/api/system/users'
import { getOrganizationList } from '@/api/system/organization'
import AddRoleVue from './AddRole.vue';
import EditRoleVue from './EditRole.vue';
import MenuPermissionsVue from './MenuPermissions.vue';
import DataPermissionsVue from './DataPermissions.vue'
export default {
    data() {
        return {
            tableData: [],
            paging: {
                p:1,
                ps: 20,
                total: 0
            },
            OrganizationsList: [], // 组织机构
            RolesList: [], // 角色列表
            searchInfo: {
                departmentId: null,
                nickname: null,
                roleId: null,
                sex: null
            },
            deleteUserIdList: []
        }
    },
    methods: {
        // // 获取角色列表
        async getRolesList() {
            const res = await getRoleList();
            console.log(res);
            if (res.code === 200) {
                this.RolesList = res.data;
                this.RolesList = [{id: -1 ,name: '全部'},...this.RolesList]
            }
        },
        // 修改用户的状态
        async changeStatus(val) {
            console.log(val);
            const info = new FormData()
            info.append("id", val.id);
            info.append("status", val.status);
            info.append("isBackstage", val.isBackstage)
            console.log(info);
            const res = await updateUserStatus(info);
            console.log(res);
            if (res.code === 200) {
                this.$message({
                    showClose: true,
                    message: '修改成功！',
                    type: 'success'
                });
                this.getRoleUserList();
            } else {
                this.$message({
                    showClose: true,
                    message: '修改失败！' + res.data,
                    type: 'error'
                });
            }
        },
        // 切换分页
        handleCurrentChange(val) {
            this.paging.p = val;
            this.getRoleUserList()
        },
        // 获取权限用户列表
        async getRoleUserList() {
            
            let info = {p: this.paging.p,ps: this.paging.ps,...this.searchInfo}
            if(this.searchInfo.sex === 4) {
                info.sex = null;
            }
            if(this.searchInfo.roleId === -1) {
                info.roleId = null;
            }
            if(this.searchInfo.departmentId === -1) {
                info.departmentId = null;
            }
            const res = await getJurisdictionUserList(info);
            console.log(res);
            if(res.code === 200) {
                this.tableData = res.data.userVOList;
                this.paging.total = res.data.dataCount;
            }
        },
        // 获取组织机构列表
        async getOrganizationsList() {
            const res = await getOrganizationList()
            console.log('组织机构');
            console.log(res);
            if (res.code === 200) {
                this.OrganizationsList = res.data;
                this.OrganizationsList = [{ id: -1, name: '全部' }, ...this.OrganizationsList]
                // this.deleteNullChildren(this.OrganizationsList)
            }
        },
        // 点击搜索
        search() {
            this.paging.p = 1;
            this.getRoleUserList();
        },
        // 点击新增
        addRole() {
            this.$refs.add.openAdd();
        },
        // // 删除角色
        // deleteRole(val) {
        //     console.log(val);
        //     this.$confirm('确认删除改角色?', '提示', {
        //         confirmButtonText: '确定',
        //         cancelButtonText: '取消',
        //         type: 'warning'
        //     }).then(async () => {
        //         const res = await deleteRoles({ id: val })
        //         console.log(res);
        //         if (res.code === 200) {
        //             this.$message({
        //                 type: 'success',
        //                 showClose: true,
        //                 message: '删除成功!'
        //             });
        //             // this.getRolesList();
        //         } else {
        //             this.$message({
        //                 type: 'success',
        //                 showClose: true,
        //                 message: '删除失败!' + res.msg
        //             });
        //         }
        //     }).catch(() => {
        //         this.$message({
        //             type: 'info',
        //             message: '已取消删除'
        //         });
        //     });

        // },
        // 获取选择的用户id
        handleSelectionChange(val) {
            console.log(val);
            this.deleteUserIdList = [];
            if (val.length > 0) {
                val.forEach(item => {
                    this.deleteUserIdList.push(item.id);
                })
            };
            // console.log(this.deleteUserIdList)
        },
        // 删除权限列表用户
         deleteUser(val) {
            if (this.deleteUserIdList.length > 0) {
                this.$confirm('确认删除该用户?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    console.log(typeof (val));
                    if (typeof (val) === 'number') {
                        this.deleteUserIdList = [val];
                    }
                    console.log(this.deleteUserIdList);
                    const res = await deleteUsersList(JSON.stringify(this.deleteUserIdList));
                    console.log(res);
                    if (res.code === 200) {
                        this.$message({
                            showClose: true,
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.getRoleUserList();
                    } else {
                        this.$message({
                            showClose: true,
                            type: 'error',
                            message: '删除失败!' + res.msg
                        });
                    }
                }).catch(() => {
                    this.$message({
                        showClose: true,
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            } else {
                this.$message({
                    showClose: true,
                    message: '请选择需要删除的用户！'
                });
            }
        },
        // 编辑
        // editRole(val) {
        //     this.$refs.edit.openEdit(val);
        // },
        // 打开菜单权限
        editMenu(val) {
            this.$refs.menu.openJurisdiction(val);
        },
        // 打开数据权限
        editData(val) {
            this.$refs.data.openJurisdiction(val);
        }
    },
    created() {
        this.getRoleUserList();
        this.getOrganizationsList();
        this.getRolesList();
    },
    components: {
        AddRoleVue,
        EditRoleVue,
        MenuPermissionsVue,
        DataPermissionsVue
    }
}
</script>

<style lang="scss" scoped>
.roleList {
    padding: 20px;

    .tableContent {
        margin-top: 20px;

        ::v-deep.el-table .el-table__body .el-table__cell {
            height: 32px !important;
            padding: 0px !important;
            line-height: 32px !important;
        }
         ::v-deep.el-table th.el-table__cell {
            height: 35px !important;
            padding: 0px !important;
            line-height: 35px !important;
        }

    }
}
</style>