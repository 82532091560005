<template>
    <div class="edit">
        <!-- 编辑角色 -->
        <el-drawer title="管理人员" 
        :show-close="false" :visible.sync="drawer" :direction="direction" :before-close="handleClose" size="50%">
            <div class="demo-drawer__content">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" size="small">
                    <el-form-item prop="name">
                        <span>角色名称</span>
                        <br />
                        <el-input v-model="ruleForm.name" style="width:45%"></el-input>
                    </el-form-item>
                </el-form>
                <div class="setting">
                    <div class="jurisdiction">
                        <div class="title">
                            <span>权限设置</span>
                            <el-button v-if="userInfo.roleId === 1" type="danger" plain size="mini" @click="editJurisdiction">编辑</el-button>
                        </div>
                        <div class="name">
                            <el-tree :data="roleDetail.menuVOList" :props="defaultProps"></el-tree>
                        </div>
                    </div>
                    <div class="roles" v-if="roleDetail.userRoleVO">
                        <div class="title">
                            <span>已选人员&nbsp;{{ roleDetail.userRoleVO.count }}</span>
                            <el-button type="danger" plain size="mini" @click="editMember">编辑</el-button>
                        </div>
                        <div class="name">
                            <span v-for="item in roleDetail.userRoleVO.userList" :key="item.id">{{ item.nickname
                            }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="demo-drawer__footer">
                <el-button size="small" @click="cancel">取 消</el-button>
                <el-button type="primary" size="small" @click="submitForm('ruleForm')"> 确定</el-button>
            </div>
        </el-drawer>
        <JurisdictionVue ref="jurisdiction" @getRoleDetail="getRoleDetail"></JurisdictionVue>
        <AddMemberVue ref="member" @getRoleDetail="getRoleDetail"></AddMemberVue>
    </div>
</template>

<script>
import JurisdictionVue from './Jurisdiction.vue'
import AddMemberVue from './/AddMember.vue';
import { getDetail, editRoles } from '@/api/system/role'
export default {
    data() {
        return {
            drawer: false,
            direction: 'rtl',
            ruleForm: {
                name: ''
            },
            userInfo: {
                roleId:null
            },
            roleId: null,
            roleDetail: {},
            defaultProps: {
          children: 'children',
          label: 'name'
        },
            rules: {
                name: [
                    { required: true, message: '请输入角色名称', trigger: 'blur' },
                    { max: 12, message: '长度在 12 个字符以内', trigger: 'change' }
                ]
            }
        };
    },
    methods: {
        handleClose(done) {
            done();
            this.$emit('getRoleUserList');
            // this.$confirm('确认关闭？')
            //     .then(_ => {
            //         done();
            //     })
            //     .catch(_ => { });
        },
        // 打开编辑模态框
        openEdit(val) {
            // console.log(JSON.parse(sessionStorage.getItem('userInfo')));
            this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
            console.log(val)
            this.roleId = val;
            this.getRoleDetail(val);
            this.drawer = true;
            
        },
        // 获取角色详情
        async getRoleDetail(val) {
            const res = await getDetail({ id: this.roleId })
            console.log(res);
            if (res.code === 200) {
                this.roleDetail = res.data;
                this.ruleForm.name = res.data.roleName;
            }
        },
        // 编辑权限
        editJurisdiction() {
            this.$refs.jurisdiction.openJurisdiction(this.roleId)
        },
        // 编辑人员
        editMember() {
            this.$refs.member.openMember(this.roleId, this.roleDetail.userRoleVO.userList)
        },
        // 点击确认
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    const res = await editRoles({ id: this.roleId, name: this.ruleForm.name });
                    console.log(res);
                    if (res.code === 200) {
                        this.$message({
                            showClose: true,
                            message: '保存成功',
                            type: 'success'
                        });
                        this.drawer = false;
                        this.$emit('getRoleUserList');
                    } else {
                        this.$message({
                            showClose: true,
                            message: '保存失败' + res.msg,
                            type: 'error'
                        });
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        cancel() {
            this.$emit('getRoleUserList');
            this.drawer = false;
        }
    },
    components: {
        JurisdictionVue, AddMemberVue
    },
    // created() {
    //     console.log(JSON.parse(sessionStorage.getItem('userInfo')));
    // }
}
</script>

<style lang="scss" scoped>
.edit {
    .demo-drawer__footer {
            .el-button {
                margin: 0 30px;
            }
        }
    ::v-deep.el-drawer {

        // padding: 0 40px;
        .demo-drawer__content {
            height: 80%;
            overflow-y: scroll;
        }

        

        .el-form {
            margin-bottom: 40px;
        }

        .setting {

            .jurisdiction,
            .roles {
                margin-bottom: 40px;

                .title {
                    span {
                        font-size: 14px;
                    }

                    .el-button {
                        margin-left: 20px;
                    }
                }

                .name {
                    margin-top: 10px;

                    span {
                        display: inline-block;
                        margin: 0 5px;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
</style>